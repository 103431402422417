import React, { useState } from "react";
import {
  AppBar,
  TextField,
  Button,
  Typography,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Container,
  Grid,
} from "@mui/material";
import { AccountCircle, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Web as WebIcon,
} from "@mui/icons-material";

const LoginForm = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "admin" && password === "thisiszealousbaby") {
      setMessage("Successfully logged in");
      localStorage.setItem("isLoggedIn", "true"); // Set authentication status to true in localStorage
      setIsLoggedIn(true); // Set authentication status to true in component state
      navigate("/loggedIn");
    } else {
      setMessage("Invalid username or password");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    handleClose();
    navigate(path);
  };

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: "#000", color: "#fff" }}
      >
        <Toolbar>
          
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{ color: "#000" }}
          >
            <MenuItem onClick={() => handleNavigation("/")}>
              <HomeIcon sx={{ mr: 1 }} />
              Home
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/aboutus")}>
              <InfoIcon sx={{ mr: 1 }} />
              About
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/services")}>
              <WebIcon sx={{ mr: 1 }} />
              Services
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/contact")}>
              <PhoneIcon sx={{ mr: 1 }} />
              Contact
            </MenuItem>
          </Menu>
          <Typography
            variant="h6"
            component="div"
            sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
          >
            Login
          </Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Container component="main" maxWidth="xs">
          <div
            style={{
              backgroundColor: "#000",
              padding: 20,
              borderRadius: 20,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{ color: "#fff", marginBottom: 20, textAlign: "center" }}
            >
              Login
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="username"
                    placeholder="Enter Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <AccountCircle style={{ color: "#fff" }} />
                      ),
                      style: { backgroundColor: "#fff" },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="password"
                    placeholder="Enter Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      startAdornment: <Lock style={{ color: "#fff" }} />,
                      style: { backgroundColor: "#fff" },
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  marginTop: 20,
                  backgroundColor: "#fff",
                  color: "#000",
                }}
              >
                Sign In
              </Button>
            </form>
            {message && (
              <Typography
                variant="subtitle1"
                style={{
                  color: message.includes("Invalid") ? "red" : "green",
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                {message}
              </Typography>
            )}
          </div>
        </Container>
      </div>

      <footer
        style={{
          backgroundColor: "#000",
          color: "#fff",
          textAlign: "center",
          padding: "20px 0",
        }}
      >
        <Typography variant="body1">
          © {new Date().getFullYear()} Zealous Virtuoso. All Rights Reserved.
        </Typography>
      </footer>
    </>
  );
};

export default LoginForm;
