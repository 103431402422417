import React from 'react';
import { Container, Typography, Button, AppBar, Toolbar, IconButton, Menu, MenuItem, Grid, Paper } from '@mui/material';
import { Menu as MenuIcon, Home as HomeIcon, Info as InfoIcon, Phone as PhoneIcon, Email as EmailIcon, Web as WebIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function Services() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    handleClose();
    navigate(path);
  };

  return (
    <>
      <AppBar position="static" style={{ backgroundColor: '#000', color: '#fff' }}>
        <Toolbar>
          
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{ color: '#000' }}
          >
            <MenuItem onClick={() => handleNavigation('/')}>
              <HomeIcon sx={{ mr: 1 }} />
              Home
            </MenuItem>
            <MenuItem onClick={() => handleNavigation('/aboutus')}>
              <InfoIcon sx={{ mr: 1 }} />
              About
            </MenuItem>
            <MenuItem onClick={() => handleNavigation('/services')}>
              <WebIcon sx={{ mr: 1 }} />
              Services
            </MenuItem>
            <MenuItem onClick={() => handleNavigation('/contact')}>
              <PhoneIcon sx={{ mr: 1 }} />
              Contact
            </MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            Services
          </Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >

      <Container maxWidth="md" style={{ color: '#000', marginTop: '20px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Our Services
        </Typography>
        <Grid container spacing={3}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <ServiceCard service={service} />
            </Grid>
          ))}
        </Grid>
        <Button variant="contained" color="primary" onClick={() => handleNavigation('/contact')}>
          Contact Us
        </Button>
      </Container>
      </div>
      
      <footer style={{ backgroundColor: '#000', color: '#fff', textAlign: 'center', padding: '20px 0' }}>
        <Typography variant="body1">
          © {new Date().getFullYear()} Zealous Virtuoso. All Rights Reserved.
        </Typography>
      </footer>
    </>
  );
}

const services = [
  {
    title: 'Website Development',
    description: 'We specialize in creating responsive, user-friendly websites that showcase your brand and drive engagement. Whether you need a simple portfolio site or a complex e-commerce platform, we\'ve got you covered.',
  },
  {
    title: 'Mobile App Development',
    description: 'Harness the power of mobile technology with our custom app development services. From iOS to Android, we develop intuitive mobile applications that enhance user experiences and streamline business operations.',
  },
  {
    title: 'Technical Solutions',
    description: 'Our team of experts provides a range of technical solutions to address your unique challenges. Whether it\'s cloud computing, cybersecurity, or software integration, we deliver robust solutions to propel your business forward.',
  },
 
];

function ServiceCard({ service }) {
  return (
    <Paper elevation={3} style={{ padding: '20px', minHeight: '200px' }}>
      <Typography variant="h6" component="h2" gutterBottom>
        {service.title}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {service.description}
      </Typography>
    </Paper>
  );
}

export default Services;
