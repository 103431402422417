import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  Phone as PhoneIcon,
  Web as WebIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  LockOutlined as LockOutlinedIcon,
} from "@mui/icons-material";
import {
  DesktopWindows as DesktopWindowsIcon,
  SettingsApplications as SettingsApplicationsIcon,
  Storage as StorageIcon,
  Build as BuildIcon,
} from "@mui/icons-material";
import "./home.css";

const Home = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    handleClose();
    navigate(path);
  };

  const handleRedirect = () => {
    window.location.href = "https://www.jashanz.com"; // Redirect to Jashanz website
  };

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: "#000", color: "#fff" }}
      >
        <Toolbar>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{ color: "#000" }}
          >
            <MenuItem onClick={() => handleNavigation("/")}>
              <HomeIcon sx={{ mr: 1 }} />
              Home
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/aboutus")}>
              <InfoIcon sx={{ mr: 1 }} />
              About
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/services")}>
              <WebIcon sx={{ mr: 1 }} />
              Services
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/contact")}>
              <PhoneIcon sx={{ mr: 1 }} />
              Contact
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/signIn")}>
            <LockOutlinedIcon sx={{ mr: 1 }} />
              Login
            </MenuItem>
          </Menu>
          <Typography
            variant="h6"
            component="div"
            sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
          >
            <img
              src="/company_logo (1).png"
              alt="Company Logo"
              style={{ width: "50px", height: "50px", filter: "invert(100%)" }}
            />
            Zealous Virtuoso
          </Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container maxWidth="md" style={{ color: "#000", paddingTop: "80px" }}>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Welcome to Zealous Virtuoso Pvt Ltd
          </Typography>
          <Typography variant="body1" gutterBottom>
            Our pioneering IT company is dedicated to crafting cutting-edge
            software solutions tailored to enhance business operations in India.
            Specializing in developing proprietary products, we focus on
            creating custom mobile apps and websites. Our commitment is to
            innovate, streamline operations, and elevate user experiences. When
            we take on client projects, we work diligently to deliver
            exceptional software solutions, ensuring our products stand out as
            industry leaders in the dynamic Indian market.
          </Typography>
        </div>
      </Container>

      

      <Container style={{ textAlign: "center" }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Our Software Websites and IT Services
        </Typography>
        <List style={{ display: "inline-block", textAlign: "left" }}>
          <ListItem>
            <ListItemIcon>
              <DesktopWindowsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Custom Web Development"
              secondary="Tailored web solutions to meet your unique business needs."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <SettingsApplicationsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Software Integration"
              secondary="Seamless integration of software solutions for enhanced efficiency."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <StorageIcon />
            </ListItemIcon>
            <ListItemText
              primary="Database Management"
              secondary="Efficient management and optimization of your databases."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText
              primary="IT Consulting"
              secondary="Expert advice and consultation to optimize your IT infrastructure."
            />
          </ListItem>
        </List>
      </Container>

      <Container maxWidth="md" style={{ color: "#000", paddingTop: "80px" }}>
        <div style={{ marginTop: "50px" }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Our Qualities & Services
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon style={{ color: "#3790ad" }} />
              </ListItemIcon>
              <ListItemText
                primary="Expertise"
                secondary="Our team comprises skilled professionals with extensive experience in software development, ensuring high-quality solutions."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon style={{ color: "#3790ad" }} />
              </ListItemIcon>
              <ListItemText
                primary="Innovation"
                secondary="We continuously explore new technologies and methodologies to deliver innovative and cutting-edge products."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon style={{ color: "#3790ad" }} />
              </ListItemIcon>
              <ListItemText
                primary="Client-Centric Approach"
                secondary="We prioritize understanding our clients' needs and tailor our solutions to exceed their expectations."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon style={{ color: "#3790ad" }} />
              </ListItemIcon>
              <ListItemText
                primary="Reliability"
                secondary="Our commitment to reliability means delivering projects on time and within budget, without compromising quality."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleOutlineIcon style={{ color: "#3790ad" }} />
              </ListItemIcon>
              <ListItemText
                primary="Support"
                secondary="We provide comprehensive support and maintenance services post-development to ensure smooth operation of our solutions."
              />
            </ListItem>
          </List>
        </div>
      </Container>

      <Container style={{ marginTop: "80px" }}>
        <div style={{ textAlign: "center", paddingTop: "80px" }}>
          <Typography variant="h5" component="h2" gutterBottom>
            About Zealous Virtuoso
          </Typography>
          <Typography variant="body1" gutterBottom>
            "Zealous Virtuoso" derives from the combination of two words,
            "Zealous" and "Virtuoso." It embodies our relentless enthusiasm and
            expertise in crafting exceptional digital solutions. At Zealous
            Virtuoso Pvt Ltd, we strive to deliver innovative software products
            and services that transcend expectations, pushing the boundaries of
            excellence in the IT industry.
          </Typography>
        </div>
      </Container>

      <footer
        style={{
          backgroundColor: "#000",
          color: "#fff",
          textAlign: "center",
          padding: "20px 0",
        }}
      >
        <Typography variant="body1">
          © {new Date().getFullYear()} Zealous Virtuoso. All Rights Reserved.
        </Typography>
      </footer>
    </>
  );
};

export default Home;
