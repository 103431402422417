import React, { useState, useEffect } from "react";
import {
  TextField,
  Container,
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  Home as HomeIcon,
  Info as InfoIcon,
  Phone as PhoneIcon,
  Web as WebIcon,
} from "@mui/icons-material";
import { Link } from "@mui/material";
import ApiService from "../ApiService";

function Contact() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [vacancies, setVacancies] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false); // State to track form submission

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        const response = await ApiService.fetchAvailableVacancies();
        console.log("result", response.data);
        setVacancies(response.data);
      } catch (error) {
        console.error("Error fetching vacancies:", error);
      }
    };
    fetchVacancies();
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    handleClose();
    navigate(path);
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const formData = {
        name: event.target.elements.name.value,
        email: event.target.elements.email.value,
        message: event.target.elements.message.value,
        datenandtime: new Date().toISOString(),
      };
      console.log("FormData", formData);
      
      await ApiService.submitContactForm(formData);
      console.log("Email sent successfully!");
      setIsFormSubmitted(true); // Set form submitted state to true
      event.target.reset();

    } catch (error) {
      console.error("Error submitting contact form:", error);
    }
  };
  

  const handlePositionChange = (event) => {
    setSelectedPosition(event.target.value);
  };

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: "#000", color: "#fff" }}
      >
        <Toolbar>
          
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{ color: "#000" }}
          >
            <MenuItem onClick={() => handleNavigation("/")}>
              <HomeIcon sx={{ mr: 1 }} />
              Home
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/aboutus")}>
              <InfoIcon sx={{ mr: 1 }} />
              About
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/services")}>
              <WebIcon sx={{ mr: 1 }} />
              Services
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/contact")}>
              <PhoneIcon sx={{ mr: 1 }} />
              Contact
            </MenuItem>
          </Menu>
          <Typography
            variant="h6"
            component="div"
            sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
          >
            Contact
          </Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
      <Container maxWidth="md" style={{ color: "#000", marginTop: "20px" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Have a question or inquiry? We'd love to hear from you! Please fill
          out the form below and we'll get back to you as soon as possible.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            id="name"
            name="name"
            label="Your Name"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            id="email"
            name="email"
            label="Your Email"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            id="message"
            name="message"
            label="Your Message"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" type="submit">
            Submit
          </Button>
        </form>
      </Container>
      </div>

      <Container maxWidth="md" style={{ color: "#000", marginTop: "40px" }}>
        <Typography variant="h4" component="h2" gutterBottom>
          Available Vacancies
        </Typography>
        <Typography variant="body1" gutterBottom>
  We are constantly seeking talented individuals to join our team. If you find a suitable position listed below, please consider submitting your CV to{" "}
  <Link href="mailto:support@zealousvirtuoso.in">support@zealousvirtuoso.in</Link>. We look forward to hearing from you!
</Typography>
        <TextField
          id="position-select"
          select
          label="Select Position"
          value={selectedPosition}
          onChange={handlePositionChange}
          variant="outlined"
          fullWidth
          margin="normal"
        >
          {vacancies.map((vacancy) => (
            <MenuItem key={vacancy.id} value={vacancy.position}>
              {vacancy.position}
            </MenuItem>
          ))}
        </TextField>

        {selectedPosition &&
          vacancies.find(
            (vacancy) => vacancy.position === selectedPosition
          ) && (
            <div>
              <Typography variant="h6" gutterBottom>
                Position: {selectedPosition}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Experience:{" "}
                {
                  vacancies.find(
                    (vacancy) => vacancy.position === selectedPosition
                  ).experience
                }
              </Typography>
              <Typography variant="body1" gutterBottom>
                Required Skills:{" "}
                {
                  vacancies.find(
                    (vacancy) => vacancy.position === selectedPosition
                  ).skills
                }
              </Typography>
            </div>
          )}
      </Container>
      
      <Snackbar
          open={isFormSubmitted} // Show Snackbar when form is submitted
          autoHideDuration={6000}
          onClose={() => setIsFormSubmitted(false)} // Close Snackbar
          message="Message sent successfully!" // Message to display
        />
       

      <footer
        style={{
          backgroundColor: "#000",
          color: "#fff",
          textAlign: "center",
          padding: "20px 0",
        }}
      >
        <Typography variant="body1">
          © {new Date().getFullYear()} Zealous Virtuoso. All Rights Reserved.
        </Typography>
      </footer>
    </>
  );
}

export default Contact;
