import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Aboutus from "./components/aboutus";
import Home from "./components/home";
import Services from "./components/services";
import Contact from "./components/contact";
import AdminDashboard from "./dashboard/admindashboard";
import LoginForm from "./components/loginform";
import "./App.css";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <Router>
      <div className="background-image">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/loggedIn"
            element={
              isLoggedIn ? (
                <AdminDashboard setIsLoggedIn={setIsLoggedIn} />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          <Route
            path="/signIn"
            element={<LoginForm setIsLoggedIn={setIsLoggedIn} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
