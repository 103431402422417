import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Paper,
  Avatar,
  Container,
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Divider,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ContactSupportOutlined as ContactIcon,
  Home as HomeIcon,
  Info as InfoIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Web as WebIcon,
} from "@mui/icons-material";

function AboutUs() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    handleClose();
    navigate(path);
  };

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: "#000", color: "#fff" }}
      >
        <Toolbar>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{ color: "#000" }}
          >
            <MenuItem onClick={() => handleNavigation("/")}>
              <HomeIcon sx={{ mr: 1 }} /> Home
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/aboutus")}>
              <InfoIcon sx={{ mr: 1 }} /> About
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/services")}>
              <WebIcon sx={{ mr: 1 }} /> Services
            </MenuItem>
            <MenuItem onClick={() => handleNavigation("/contact")}>
              <PhoneIcon sx={{ mr: 1 }} /> Contact
            </MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            About Us
          </Typography>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="lg" style={{ marginTop: "20px" }}>
          <Typography variant="h4" component="h1" gutterBottom>
            About Our Company
          </Typography>
          <Typography variant="body1" paragraph>
            Zealous Virtuoso is a pioneering IT company headquartered in Pune,
            Maharashtra, India, renowned for its excellence in providing
            innovative product and service-based solutions. With a steadfast
            commitment to technological advancement and customer satisfaction,
            we redefine the landscape of event management through our flagship
            product, Jashanz.
          </Typography>
          <Divider style={{ margin: "20px 0" }} />
          <Typography variant="h5" component="h2" gutterBottom>
            Established with a vision to transform conventional event planning
            methodologies, Zealous Virtuoso stands at the forefront of
            innovation in the IT industry. Our team of dedicated professionals
            harnesses cutting-edge technology to deliver unparalleled solutions
            tailored to meet the dynamic needs of our diverse clientele.
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            Our Specialization
          </Typography>
          <Typography variant="body1" paragraph>
            At Zealous Virtuoso, we specialize in both product and service-based
            offerings, ensuring comprehensive support across all facets of IT
            solutions. From conceptualization to implementation, our expertise
            spans a wide array of domains, enabling us to deliver impactful
            solutions that drive success for businesses across various
            industries.
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid container item direction="column" alignItems="center">
                <Grid item>
                  <Paper
                    elevation={3}
                    style={{
                      padding: "50px",
                      textAlign: "center",
                      borderRadius: "20px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Avatar
                      style={{ width: "70px", height: "70px" }}
                      />
                    </div>
                    <Typography variant="subtitle1">Anand Sasane</Typography>
                    <Typography variant="body2">
                      Chief Executive Officer
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="mailto:ceo@zealousvirtuoso.in"
                      style={{ marginTop: "10px" }}
                    >
                      Email CEO
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid container item direction="column" alignItems="center">
                <Grid item>
                  <Paper
                    elevation={3}
                    style={{
                      padding: "50px",
                      textAlign: "center",
                      borderRadius: "20px",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Avatar
                      style={{ width: "70px", height: "70px" }}
                      />
                    </div>
                    <Typography variant="subtitle1">Akshay Gaikwad</Typography>
                    <Typography variant="body2">
                      Chief Financial Officer
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="mailto:cfo@zealousvirtuoso.in"
                      style={{ marginTop: "10px" }}
                    >
                      Email CFO
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider style={{ margin: "20px 0" }} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNavigation("/contact")}
          >
            <ContactIcon style={{ marginRight: "8px" }} /> Contact Us
          </Button>
        </Container>
      </div>

      <footer
        style={{
          backgroundColor: "#000",
          color: "#fff",
          textAlign: "center",
          padding: "20px 0",
          marginTop: "20px",
        }}
      >
        <Typography variant="body1">
          © {new Date().getFullYear()} Zealous Virtuoso. All Rights Reserved.
        </Typography>
      </footer>
    </>
  );
}

export default AboutUs;
