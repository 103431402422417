import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Badge,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import { Box, TextField, Grid, Card, CardContent, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import ApiService from "../ApiService";
import NotificationPanel from "./notificationpanel";

const AdminDashboard = ({ setIsLoggedIn }) => {
  const [vacancies, setVacancies] = useState([]);
  const [newVacancy, setNewVacancy] = useState({
    position: "",
    experience: "",
    skills: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editVacancyId, setEditVacancyId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [newEmployee, setNewEmployee] = useState({
    name: "",
    position: "",
    photo: "",
  });
  const [isEmpEditing, setIsEmpEditing] = useState(false);
  const [editEmployeeId, setEditEmployeeId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    fetchVacancies();
    fetchEmployees();
  }, []);

  const fetchVacancies = async () => {
    try {
      const response = await ApiService.fetchAvailableVacancies();
      setVacancies(response.data);
    } catch (error) {
      console.error("Error fetching vacancies:", error);
      // Handle error
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await ApiService.getAllEmployees();
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
      // Handle error
    }
  };

  const handleAddVacancy = async () => {
    try {
      await ApiService.addVacancy(newVacancy);
      await fetchVacancies();
      setNewVacancy({ position: "", experience: "", skills: "" });
    } catch (error) {
      console.error("Error adding vacancy:", error);
      // Handle error
    }
  };

  const handleEditVacancy = (id) => {
    setEditVacancyId(id);
    setIsEditing(true);
  };

  const handleSaveVacancy = async (id) => {
    try {
      console.log("id" + id);
      console.log("newVacancy" + newVacancy);

      await ApiService.updateVacancy(id, newVacancy);
      await fetchVacancies();
      setEditVacancyId(null);
      setIsEditing(false);
      setNewVacancy({ position: "", experience: "", skills: "" });
    } catch (error) {
      console.error("Error saving vacancy:", error);
      // Handle error
    }
  };

  const handleDeleteVacancy = async (id) => {
    try {
      await ApiService.deleteVacancy(id);
      await fetchVacancies();
    } catch (error) {
      console.error("Error deleting vacancy:", error);
      // Handle error
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewVacancy((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAddEmployee = async () => {
    try {
      await ApiService.addEmployee(newEmployee);
      await fetchEmployees();
      setNewEmployee({ name: "", position: "", photo: "" });
    } catch (error) {
      console.error("Error adding employee:", error);
      // Handle error
    }
  };

  const handleEditEmployee = (id) => {
    setEditEmployeeId(id);
    setIsEmpEditing(true);
  };

  const handleSaveEmployee = async (id) => {
    try {
      await ApiService.updateEmployee(id, newEmployee);
      await fetchEmployees();
      setEditEmployeeId(null);
      setIsEmpEditing(false);
      setNewEmployee({ name: "", position: "", photo: "" });
    } catch (error) {
      console.error("Error saving employee:", error);
      // Handle error
    }
  };

  const handleDeleteEmployee = async (id) => {
    try {
      await ApiService.deleteEmployee(id);
      await fetchEmployees();
    } catch (error) {
      console.error("Error deleting employee:", error);
      // Handle error
    }
  };

  const handleEmpChange = (e) => {
    const { name, value } = e.target;
    setNewEmployee((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAboutClick = () => {
    handleClose();
    navigate("/aboutus");
  };

  const handleServicesClick = () => {
    handleClose();
    navigate("/services");
  };

  const handleContactClick = () => {
    handleClose();
    navigate("/contact");
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false);
  };

    const [showNotifications, setShowNotifications] = useState(false);
  
    const toggleNotifications = () => {
      setShowNotifications(!showNotifications);
    };
  
 
  


  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: "#000", color: "#fff" }}
      >
        <Toolbar>
          
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{ color: "#000" }}
          >
            <MenuItem onClick={handleClose}>Home</MenuItem>
            <MenuItem onClick={handleAboutClick}>About</MenuItem>
            <MenuItem onClick={handleServicesClick}>Services</MenuItem>
            <MenuItem onClick={handleContactClick}>Contact</MenuItem>
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>

          </Menu>
          <Typography
            variant="h6"
            component="div"
            sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
          >
            <img
              src="/company_logo (1).png"
              alt="Company Logo"
              style={{ width: "50px", height: "50px", filter: "invert(100%)" }}
            />
            Zealous Virtuoso
          </Typography>
          <IconButton color="inherit" onClick={toggleNotifications}>
            <Badge color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {showNotifications && (
         <NotificationPanel />
      )}

      <Container
        style={{
          backgroundColor: "#000",
          padding: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          borderRadius: 20,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
        }}
      >
        {/* Post Job Vacancy Section */}
        <div style={{ textAlign: "center" }}>
          <Typography variant="h4" gutterBottom style={{ color: "#fff" }}>
            Post Job Vacancy
          </Typography>
        </div>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="position"
              label="Position"
              fullWidth
              value={newVacancy.position}
              onChange={handleChange}
              style={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="experience"
              label="Experience"
              fullWidth
              value={newVacancy.experience}
              onChange={handleChange}
              style={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="skills"
              label="Skills"
              fullWidth
              value={newVacancy.skills}
              onChange={handleChange}
              style={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isEditing ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveVacancy(editVacancyId)}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddVacancy}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add Vacancy
              </Button>
            )}
          </Grid>
        </Grid>

        <Box mt={4}>
          <Typography variant="h4" gutterBottom style={{ color: "#fff" }}>
            Job Vacancies
          </Typography>
          {vacancies.map((vacancy) => (
            <Card
              key={vacancy.id}
              variant="outlined"
              style={{ marginBottom: "10px" }}
            >
              <CardContent>
                {isEditing && editVacancyId === vacancy.id ? (
                  <>
                    <TextField
                      name="position"
                      label="Position"
                      fullWidth
                      value={newVacancy.position}
                      onChange={handleChange}
                    />
                    <TextField
                      name="experience"
                      label="Experience"
                      fullWidth
                      value={newVacancy.experience}
                      onChange={handleChange}
                    />
                    <TextField
                      name="skills"
                      label="Skills"
                      fullWidth
                      value={newVacancy.skills}
                      onChange={handleChange}
                    />
                  </>
                ) : (
                  <>
                    <Typography variant="h6">{vacancy.position}</Typography>
                    <Typography variant="body1">
                      Experience: {vacancy.experience}
                    </Typography>
                    <Typography variant="body1">
                      Skills: {vacancy.skills}
                    </Typography>
                  </>
                )}
                <Box mt={2}>
                  {isEditing && editVacancyId === vacancy.id ? (
                    <>
                      <IconButton
                        color="primary"
                        onClick={() => handleSaveVacancy(vacancy.id)}
                      >
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => setIsEditing(false)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditVacancy(vacancy.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteVacancy(vacancy.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Container>

      <Container
        style={{
          backgroundColor: "#000",
          padding: "20px",
          marginTop: "20px",
          marginBottom: "20px",
          borderRadius: 20,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
        }}
      >
        {/* Post Employee Section */}
        <div style={{ textAlign: "center" }}>
          <Typography variant="h4" gutterBottom style={{ color: "#fff" }}>
            Add New Employee
          </Typography>
        </div>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="name"
              label="Name"
              fullWidth
              value={newEmployee.name}
              onChange={handleEmpChange}
              style={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="position"
              label="Position"
              fullWidth
              value={newEmployee.position}
              onChange={handleEmpChange}
              style={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="photo"
              label="Photo URL"
              fullWidth
              value={newEmployee.photo}
              onChange={handleEmpChange}
              style={{ backgroundColor: "white" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {isEmpEditing ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveEmployee(editEmployeeId)}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEmployee}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add Employee
              </Button>
            )}
          </Grid>
        </Grid>

        <Box mt={4}>
          <Typography variant="h4" gutterBottom style={{ color: "#fff" }}>
            Employee List
          </Typography>
          {employees.map((employee, index) => (
            <Card
              key={employee.id} // Assuming employee object has an id
              variant="outlined"
              style={{ marginBottom: "10px" }}
            >
              <CardContent>
                {isEmpEditing && editEmployeeId === employee.id ? (
                  <>
                    <TextField
                      name="name"
                      label="Name"
                      fullWidth
                      value={newEmployee.name}
                      onChange={handleEmpChange}
                    />
                    <TextField
                      name="position"
                      label="Position"
                      fullWidth
                      value={newEmployee.position}
                      onChange={handleEmpChange}
                    />
                    <TextField
                      name="photo"
                      label="Photo URL"
                      fullWidth
                      value={newEmployee.photo}
                      onChange={handleEmpChange}
                    />
                  </>
                ) : (
                  <>
                    <Typography variant="h6">{employee.name}</Typography>
                    <Typography variant="body1">
                      Position: {employee.position}
                    </Typography>
                    <Avatar alt={employee.name} src={employee.photo} />
                  </>
                )}
                <Box mt={2}>
                  {isEmpEditing && editEmployeeId === employee.id ? (
                    <>
                      <IconButton
                        color="primary"
                        onClick={() => handleSaveEmployee(employee.id)}
                      >
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => setIsEmpEditing(false)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditEmployee(employee.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDeleteEmployee(employee.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Container>
      <footer
        style={{
          backgroundColor: "#000",
          color: "#fff",
          textAlign: "center",
          padding: "20px 0",
        }}
      >
        <Typography variant="body1">
          © {new Date().getFullYear()} Zealous Virtuoso. All Rights Reserved.
        </Typography>
      </footer>
    </>
  );
};

export default AdminDashboard;
