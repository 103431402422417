import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ApiService from "../ApiService";

const NotificationPanel = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Call addSampleNotifications when component mounts
    addSampleNotifications();
  }, []); // Empty dependency array ensures the effect runs only once

  const addSampleNotifications = async () => {
    try {
      const response = await ApiService.getMessages();
      if (response && response.data) {
        // Modify the response to include 'datenandtime' instead of 'timestamp'
        const modifiedResponse = response.data.map((notification) => ({
          ...notification,
          datenandtime: notification.datenandtime,
        }));
        setNotifications(modifiedResponse);
        setShowNotifications(true); // Show notifications automatically after adding samples
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const deleteMessage = async (id) => {
    try {
      await ApiService.deleteMessage(id);
      // After deletion, update the notifications state by removing the deleted message
      setNotifications(notifications.filter(notification => notification.id !== id));
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  return (
    <>
      {showNotifications && (
        <Container
          style={{
            backgroundColor: "#000",
            color: "#fff", // Text color set to white
            padding: "20px",
            marginTop: "20px",
            marginBottom: "20px",
            borderRadius: 20,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <Typography variant="h4" gutterBottom>
              MessageMinder
            </Typography>
          </div>
          <List>
            {notifications.map((notification) => (
              <ListItem
                key={notification.id}
                style={{ borderBottom: "1px solid #ccc", cursor: "pointer" }}
              >
                <ListItemAvatar>
                  <Avatar>{notification.name.charAt(0)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Tooltip title={notification.message} placement="top">
                      <span>{notification.message}</span>
                    </Tooltip>
                  }
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ color: "#ebe8e8" }}
                      >
                        {notification.name}
                      </Typography>{" "}
                      -{" "}
                      <div style={{ color: "#c2c0c0" }}>
                        {notification.email} - {notification.datenandtime}
                      </div>
                    </>
                  }
                />
                <IconButton aria-label="delete" onClick={() => deleteMessage(notification.id)}>
                  <DeleteIcon style={{ color: "#FFFFFF" }} />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Container>
      )}
    </>
  );
};

export default NotificationPanel;
