import axios from "axios";

const BASE_URL =
  "https://zvbackend-env.eba-z7tr8tgm.ap-south-1.elasticbeanstalk.com/admin";

class ApiService {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
    });
  }

  // Employees API Methods

  getAllEmployees() {
    return this.axiosInstance.get("/employees");
  }

  addEmployee(employeeData) {
    return this.axiosInstance.post("/employees", employeeData);
  }

  updateEmployee(employeeId, employeeData) {
    return this.axiosInstance.put(`/employees/${employeeId}`, employeeData);
  }

  deleteEmployee(employeeId) {
    return this.axiosInstance.delete(`/employees/${employeeId}`);
  }

  // Vacancies API Methods

  fetchAvailableVacancies() {
    return this.axiosInstance.get("/vacancies");
  }

  addVacancy(vacancyData) {
    return this.axiosInstance.post("/vacancies", vacancyData);
  }

  updateVacancy(vacancyId, vacancyData) {
    return this.axiosInstance.put(`/vacancies/${vacancyId}`, vacancyData);
  }

  deleteVacancy(vacancyId) {
    return this.axiosInstance.delete(`/vacancies/${vacancyId}`);
  }

  submitContactForm(formData) {
    return this.axiosInstance.post(`/messages`, formData);
  }
  getMessages() {
    return this.axiosInstance.get(`/messages`);
  }
  deleteMessage(id) {
    return this.axiosInstance.delete(`/messages/${id}`);
  }
}

export default new ApiService();
